import { components } from 'react-select';

import { DropdownHint } from './styles';

// It only exists because we want to have correct roles on elements
export const Option = (props) => (
  <components.Option
    {...props}
    innerProps={{
      ...props.innerProps,
      role: 'option',
      'data-value': props.value,
    }}
  >
    {props.children}
  </components.Option>
);

export const MenuList = ({ innerProps, selectProps, children, ...props }) => (
  <components.MenuList
    {...props}
    selectProps={selectProps}
    innerProps={{
      ...innerProps,
      'data-testid': 'select-options-wrapper',
      id: `select-options-wrapper_${selectProps?.name}`,
    }}
  >
    {children}
    <DropdownHint>{selectProps?.dropdownHint}</DropdownHint>
  </components.MenuList>
);
