export const NOTIFICATION_KIND = {
  info: 'info',
  warning: 'warning',
  error: 'error',
  success: 'success',
};

export const ICONS = {
  [NOTIFICATION_KIND.success]: 'check_circle',
  [NOTIFICATION_KIND.warning]: 'error_outline',
  [NOTIFICATION_KIND.error]: 'highlight_off',
  [NOTIFICATION_KIND.info]: 'info',
};

export const buttonTextType = ({ buttonAction, buttonText, buttonLink }) => {
  if (
    ((buttonAction || buttonLink) && !buttonText) ||
    (!buttonAction && !buttonLink && buttonText)
  ) {
    return new Error(
      `'buttonText' and 'buttonAction' props must be present to include a button.`
    );
  }
  if (buttonAction && buttonLink) {
    return new Error(
      `'buttonAction' and 'buttonLink' props can't be used together.`
    );
  }
};

export const elementsNotAligned = ({ titleY, descriptionY, buttonY }) =>
  (titleY && descriptionY && titleY < descriptionY) ||
  (titleY && buttonY && titleY < buttonY) ||
  (descriptionY && buttonY && descriptionY < buttonY);
