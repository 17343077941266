import { useRef, useState } from 'react';
import { elementsNotAligned } from './utils';
import { useCookies } from 'react-cookie';

export const useNotificationValues = ({
  cookieName,
  closeButtonId,
  id,
  title,
  forceMultiline,
  hasButtonLink,
  hideActionButton,
  hasButtonAction,
}) => {
  const titleRef = useRef();
  const descriptionRef = useRef();
  const buttonRef = useRef();

  const prefixedCookieName = `hideNotification-${
    cookieName || closeButtonId || id || title
  }`;
  const [cookies, setCookie] = useCookies();
  const [show, setShow] = useState(!cookies[prefixedCookieName]);

  const hideNotification = () => {
    setShow(false);
    setCookie(prefixedCookieName, 1);
  };

  const showActionButton =
    !hideActionButton && (hasButtonAction || hasButtonLink);
  const multiline =
    forceMultiline ||
    elementsNotAligned({
      titleY: titleRef.current?.offsetTop,
      descriptionY: descriptionRef.current?.offsetTop,
      buttonY: buttonRef.current?.offsetTop,
    });

  return {
    buttonRef,
    descriptionRef,
    hideNotification,
    multiline,
    show,
    showActionButton,
    titleRef,
  };
};
