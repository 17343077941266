import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { NotificationTextWrapper } from './style';

export const NotificationText = forwardRef(({ text, multiline }, ref) => {
  const attrs = {
    ref,
    multiline,
    id: 'notification-description',
    role: 'note',
  };

  return typeof text === 'function' ? (
    <NotificationTextWrapper {...attrs}>{text()}</NotificationTextWrapper>
  ) : (
    <NotificationTextWrapper
      {...attrs}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
});

NotificationText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  multiline: PropTypes.bool,
};

NotificationText.displayName = 'NotificationText';
