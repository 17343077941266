import styled from 'styled-components';
import { withCustomProps } from '../styles/utils';
import {
  backgroundBlue,
  blue,
  errorRed,
  green,
  lightGray,
  paleBlueAccented,
  paleGreen,
  paleGreenAccented,
  paleRed,
  paleRedAccented,
  paleYellow,
  paleYellowAccented,
  yellow,
} from '../styles/variables';

export const NOTIFICATION_COLOR = {
  error: {
    background: paleRed,
    backgroundHover: paleRedAccented,
    color: errorRed,
  },
  warning: {
    background: paleYellow,
    backgroundHover: paleYellowAccented,
    color: yellow,
  },
  info: {
    background: backgroundBlue,
    backgroundHover: paleBlueAccented,
    color: blue,
  },
  success: {
    background: paleGreen,
    backgroundHover: paleGreenAccented,
    color: green,
  },
};

export const NotificationWrapper = withCustomProps(styled.div`
  display: grid;
  grid-template-areas: 'icon content close';
  grid-auto-columns: auto 1fr auto;
  color: ${lightGray};
  font-size: var(--font-size);
  border-radius: 3px;
  padding: 16px;
  gap: 8px;
  background-color: ${({ kind }) => NOTIFICATION_COLOR[kind]?.background};
  margin-top: ${({ marginTop }) => marginTop || '0'};
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'};
`);

export const IconContainer = withCustomProps(styled.div`
  grid-area: icon;
  display: flex;
  align-items: ${({ multiline }) => (multiline ? 'flex-start' : 'center')};
  color: ${({ kind }) => NOTIFICATION_COLOR[kind]?.color};
`);

export const ContentWrapper = withCustomProps(styled.div`
  grid-area: content;
  display: flex;
  align-items: ${({ multiline }) => (multiline ? 'flex-start' : 'center')};
  flex-direction: ${({ multiline }) => (multiline ? 'column' : 'row')};
  flex-wrap: wrap;
  overflow: hidden;
  word-break: break-word;
`);

export const Title = styled.span`
  font-weight: bold;
`;

export const NotificationTextWrapper = withCustomProps(styled.span`
  flex-grow: 1;
  word-break: break-word;
  text-align: justify;

  a {
    text-decoration: underline;
    color: ${lightGray};
  }

  ${({ multiline }) =>
    !multiline &&
    `
    &::before {
      padding: 0 4px;
      content: '•';
      font-weight: bold;
    }
  `}
`);

export const ButtonContainer = withCustomProps(styled.div`
  margin-left: auto;
  ${({ addRightMargin }) => addRightMargin && 'margin-right: 24px;'}
  a {
    border: 1px solid ${({ kind }) => NOTIFICATION_COLOR[kind]?.color};
    background: ${({ kind }) => NOTIFICATION_COLOR[kind]?.background};
    color: ${({ kind }) => NOTIFICATION_COLOR[kind]?.color};
    border-radius: 3px;
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
      background: ${({ kind }) => NOTIFICATION_COLOR[kind]?.backgroundHover};
      color: ${({ kind }) => NOTIFICATION_COLOR[kind]?.color};
    }
  }
`);

export const HideButton = styled.button`
  position: absolute;
  top: 8px;
  right: 0;
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;

  &:hover {
    opacity: 0.7;
  }
`;
