import PropTypes from 'prop-types';
import React from 'react';
import SelectComponent from 'react-select';

import useI18n from '../../../../lib/use_i18n';
import { HiddenInput } from '../../styles/form';
import { InputWrapper } from '../input_wrapper';
import { MenuList, Option } from './select_components';
import { useSelectValues } from './use_select_values';

/**
 * Renders a Dropdown component based on react-select.
 *
 *   <Select
 *     label="label"
 *     required="required"
 *     placeholder="placeholder"
 *     value={{label: 'label', value: 'value'}}
 *     options={[{label: 'label1', value: value1}, {label: 'label2', value: value2}]}
 *     errorText="error"
 *     onChange={fn()}
 *   />
 *
 */
export const Select = ({
  clearable,
  customClassName,
  customSelectStyles,
  dropdownHint,
  disabled,
  errorText,
  hint,
  hintId,
  id,
  label,
  labelProps,
  maxValues,
  multi,
  name,
  namespace,
  onChange,
  options,
  placeholder,
  required,
  searchable,
  showScrollbar,
  value,
  ...props
}) => {
  const { translate } = useI18n();
  const { selectInputId, updateSelected, styles, selected } = useSelectValues({
    value,
    maxValues,
    onChange,
    showScrollbar,
    errorText,
    customSelectStyles,
    name,
  });
  return (
    <InputWrapper
      disabled={disabled}
      errorText={errorText}
      labelProps={{ label, required, ...labelProps, htmlFor: selectInputId }}
      name={name}
      namespace={namespace}
      hint={hint}
      hintId={hintId}
    >
      <SelectComponent
        blurInputOnSelect={true}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        inputId={selectInputId}
        dropdownHint={dropdownHint}
        aria-label={label}
        className={customClassName}
        components={{ Option, MenuList }}
        id={id || `select_container_${name}`}
        isClearable={clearable}
        isDisabled={disabled}
        isMulti={multi}
        isSearchable={searchable}
        onChange={updateSelected}
        options={options}
        name={name}
        placeholder={
          placeholder ? placeholder : translate('forms.dropdown.placeholder')
        }
        styles={styles}
        // update component after value is changed to a falsy value
        key={`select_key__${selected}`}
        value={selected}
        menuPortal
        {...props}
      />
      <HiddenInput
        readOnly
        id={name}
        name={name}
        value={value?.value}
        onFocus={() => {
          // handles focus-on-error from react-final-form
          // redirects focus to the select input
          document.getElementById(selectInputId)?.focus();
        }}
      />
    </InputWrapper>
  );
};

Select.defaultProps = {
  disabled: false,
  multi: false,
  clearable: false,
  maxValues: 3,
};

Select.propTypes = {
  clearable: PropTypes.bool,
  customClassName: PropTypes.string,
  customSelectStyles: PropTypes.object,
  disabled: PropTypes.bool,
  dropdownHint: PropTypes.string,
  errorText: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  maxValues: PropTypes.number,
  multi: PropTypes.bool,
  name: PropTypes.string,
  namespace: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  labelProps: InputWrapper.propTypes.labelProps,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
    PropTypes.array,
  ]).isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  searchable: PropTypes.bool,
  showScrollbar: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
};
