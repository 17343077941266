import { useEffect, useState } from 'react';

import { generateStyles } from './styles';

export const useSelectValues = ({
  value,
  maxValues,
  onChange,
  customSelectStyles,
  showScrollbar,
  errorText,
  name,
}) => {
  const [selected, setSelected] = useState(value);

  const updateSelected = (selected) => {
    if (Array.isArray(selected) && selected.length > maxValues) {
      return; // Do nothing if the array exceeds the maxValues limit
    }
    setSelected(selected);
  };

  useEffect(() => {
    // we cannot set an empty string or null because of that bug
    // https://github.com/final-form/react-final-form/issues/430
    onChange(selected || undefined);
  }, [selected]);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  /**
   * There is no out of the box way to style the scrollbar, so in case we want to show
   * the scrollbar, we can use these custom pre-defined css rules by using `showScrollbar` prop.
   */
  const styles = customSelectStyles
    ? customSelectStyles
    : generateStyles({ hasError: Boolean(errorText), showScrollbar });

  // id for search input field that is used
  // to switch focus from the HiddenInput to search field,
  // utilizing final-forms default mechanism
  const selectInputId = `select_input_${name}`;

  return {
    styles,
    selectInputId,
    updateSelected,
    selected,
  };
};
