import PropTypes from 'prop-types';
import React from 'react';
import useI18n from '../../../lib/use_i18n';
import { NotificationText } from './notification_text';
import {
  ButtonContainer,
  ContentWrapper,
  HideButton,
  IconContainer,
  NotificationWrapper,
  Title,
} from './style';
import { useNotificationValues } from './use_notification_values';
import { buttonTextType, ICONS, NOTIFICATION_KIND } from './utils';

export const Notification = ({
  actionButtonId,
  buttonAction,
  buttonLink,
  buttonTarget,
  buttonText,
  className,
  closeButtonId,
  cookieName,
  forceMultiline,
  hideActionButton,
  id,
  kind,
  marginBottom,
  marginTop,
  showCloseButton,
  text,
  title,
}) => {
  const { translate } = useI18n();

  const {
    buttonRef,
    descriptionRef,
    hideNotification,
    multiline,
    show,
    showActionButton,
    titleRef,
  } = useNotificationValues({
    closeButtonId,
    cookieName,
    forceMultiline,
    hasButtonAction: !!buttonAction,
    hasButtonLink: !!buttonLink,
    hideActionButton,
    id,
    title,
  });

  return show ? (
    <NotificationWrapper
      data-kind={kind}
      className={className}
      id={id}
      role="alert"
      marginTop={marginTop}
      marginBottom={marginBottom}
      kind={kind}
    >
      <IconContainer kind={kind} multiline={multiline}>
        <span className="material-symbols-outlined">{ICONS[kind]}</span>
      </IconContainer>

      <ContentWrapper multiline={multiline} id="notification-content">
        <Title
          id="notification-title"
          ref={titleRef}
          role="heading"
          aria-level="3"
        >
          {title || translate(`notifications.default_title.${kind}`)}
        </Title>
        <NotificationText
          text={text}
          multiline={multiline}
          ref={descriptionRef}
        />
      </ContentWrapper>

      {showActionButton && (
        <ButtonContainer kind={kind} addRightMargin={showCloseButton}>
          <a
            className="button btn"
            ref={buttonRef}
            id={actionButtonId}
            href={buttonLink || '#'}
            target={buttonTarget}
            onClick={() => buttonAction?.()}
          >
            {buttonText}
          </a>
        </ButtonContainer>
      )}

      {showCloseButton && (
        <HideButton onClick={hideNotification}>
          <span
            id={closeButtonId || 'hide-notification-button'}
            className="material-symbols-outlined"
          >
            close
          </span>
        </HideButton>
      )}
    </NotificationWrapper>
  ) : null;
};

Notification.defaultProps = {
  actionButtonId: 'notification-action-button',
};

Notification.propTypes = {
  actionButtonId: PropTypes.string,
  buttonAction: PropTypes.func,
  buttonLink: PropTypes.string,
  buttonTarget: PropTypes.string,
  className: PropTypes.string,
  closeButtonId: PropTypes.string,
  cookieName: PropTypes.string,
  forceMultiline: PropTypes.bool,
  hideActionButton: PropTypes.bool,
  id: PropTypes.string,
  kind: PropTypes.oneOf(Object.values(NOTIFICATION_KIND)).isRequired,
  marginBottom: PropTypes.string,
  marginTop: PropTypes.string,
  showCloseButton: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  title: PropTypes.string,
  buttonText: buttonTextType,
};
