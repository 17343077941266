import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Field } from './field';
import styled from 'styled-components';
import { InputWrapper } from './input_wrapper';
import { generateKeyDownHandler, getError, INPUT_TYPE } from './utils';
import { Flex } from '../styles/flex';
import { fontSize, lightBlue, placeholderGray } from '../styles/variables';
import useI18n from '../../../lib/use_i18n';
import { isURLValid } from '../../../utils';
import BalloonIcon from '../balloon_icon';

const IconWrapper = styled.div`
  display: flow-root;
  margin-top: -32px;
  width: 24px;
`;

const InputIconWrapper = styled.div`
  width: 100%;
`;

const StyledLink = styled.a`
  color: ${placeholderGray};
  display: flex;
  align-items: center;
  &:active,
  &:visited,
  &:focus,
  &:hover {
    text-decoration: none;
  }
  &:hover {
    color: ${lightBlue};
  }
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

const CounterWrapper = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
  color: ${placeholderGray};
  font-size: ${fontSize};
  margin-top: 4px;
`;

/**
 * Renders an input stylized with Bootstrap, for use with react-final-form.
 * The component includes a title as well as the <input> element.
 *
 * In addition to the usual values for the input's type attribute allowed by
 * the HTML specification, this component accepts a type of 'integer'. This
 * produces an input with type="number", with further restrictions so that only
 * unsigned integer values may be entered by the user. Decimal points, negative
 * signs, and scientific notation are disallowed. Be aware that users may still
 * paste invalid values into the input. Server side validation is of course
 * required.
 *
 *     <InputField name="email" label="Email" placeholder="placeholder" required="true" />
 */
const InputField = ({
  name, // The field's name attribute
  label, // The field's name as displayed to the user
  required, // Shows visually that the field must be filled in
  className, // CSS class for the component's outer container

  // to be passed to the InputWrapper component,
  // together with disabled, required, className, name
  labelProps,
  hidden,
  hint,
  hintId,
  namespace, // will be used for creating the HTML id for unique validation errors,
  warningText,

  // needed for analytics

  // DOM attributes passed directly to the <input> element
  placeholder,
  disabled = false,
  type, // see above
  min,
  max,
  maxLength,
  step,
  autoComplete,
  title,
  icon,
  rows,

  ...fieldProps //exclusive to the Field component
}) => {
  return (
    <Field {...fieldProps} disabled={disabled} name={name} required={required}>
      {({ input, meta }) => (
        <Input
          autoComplete={autoComplete}
          className={className}
          disabled={disabled}
          errorText={getError(meta)}
          warningText={warningText}
          hidden={hidden}
          hint={hint}
          hintId={hintId}
          icon={icon}
          inputAttrs={input}
          label={label}
          labelProps={labelProps}
          max={max}
          maxLength={maxLength}
          min={min}
          name={name}
          namespace={namespace}
          placeholder={placeholder}
          required={required}
          rows={rows}
          step={step}
          title={title}
          type={type}
        />
      )}
    </Field>
  );
};

export const Input = ({
  autoComplete,
  className,
  disabled,
  errorText,
  hidden,
  hint,
  hintId,
  icon,
  inputAttrs,
  label,
  labelProps,
  max,
  maxLength,
  min,
  name,
  namespace,
  placeholder,
  required,
  rows,
  step,
  title,
  type,
  value,
  warningText,
}) => {
  const id = `${name}_field`;
  const classNames = ['form-control'];

  // use `value` for controlled input, `defaultValue` otherwise
  const [localValue, setLocaleValue] = useState(
    inputAttrs?.onChange ? inputAttrs.value : inputAttrs.defaultValue || ''
  );
  const { translate: t } = useI18n('forms.input');
  const keyHandler = generateKeyDownHandler({ type, max });
  const handleOnChange = (e) => {
    inputAttrs?.onChange && inputAttrs?.onChange(e);
    setLocaleValue(e.target.value);
  };

  if (icon) classNames.push('with-icon');

  const InputEl = useCallback(
    (props) =>
      type === INPUT_TYPE.TEXTAREA ? (
        <textarea {...props} />
      ) : (
        <input {...props} />
      ),
    [type]
  );

  return hidden ? null : (
    <InputWrapper
      hint={hint}
      hintId={hintId}
      className={className}
      disabled={disabled}
      errorText={errorText}
      labelProps={{ label, required, htmlFor: id, ...labelProps }}
      name={name}
      namespace={namespace}
      warningText={warningText}
    >
      <Flex>
        <InputIconWrapper>
          <InputEl
            name={name}
            {...{
              autoComplete,
              disabled,
              id,
              max,
              maxLength,
              min,
              placeholder,
              rows,
              step,
              title,
              type: type === INPUT_TYPE.INTEGER ? 'number' : type,
              value,
              ...inputAttrs,
            }}
            className={classNames.join(' ')}
            onChange={handleOnChange}
            onKeyDown={keyHandler}
          />
          {icon && (
            <IconWrapper>
              <img src={icon} width="24" height="24" alt="" />
            </IconWrapper>
          )}
        </InputIconWrapper>
        {type === INPUT_TYPE.LINK && (
          <StyledLink
            disabled={!isURLValid(localValue)}
            href={localValue}
            rel="noreferrer"
            target="_blank"
          >
            <BalloonIcon
              tooltip={t('.link_icon_title')}
              size={24}
              icon="open_in_new"
            />
          </StyledLink>
        )}
      </Flex>
      {maxLength ? (
        <CounterWrapper>
          {localValue.length} / {maxLength}
        </CounterWrapper>
      ) : null}
    </InputWrapper>
  );
};

export default InputField;

const sharedProps = {
  ...InputWrapper.propTypes,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  namespace: PropTypes.string,
  required: PropTypes.bool,
  hidden: PropTypes.bool,

  // DOM attributes passed directly to the <input> element
  autoComplete: PropTypes.bool,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  step: PropTypes.number,
  title: PropTypes.string,
  type: PropTypes.string,
};

InputField.defaultProps = {
  disabled: false,
  type: 'text',
};

InputField.propTypes = {
  ...sharedProps,

  // react-final-form Field event handlers
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

Input.defaultProps = {
  ...InputField.defaultProps,
};

Input.propTypes = {
  ...sharedProps,
  inputAttrs: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
