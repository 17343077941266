import PropTypes from 'prop-types';

import { getError } from '../utils';
import { Field } from '../field';
import { flattenOptions } from './utils';
import { Select } from './select';

/**
 *
 * An adapter component allowing Select component to be easily used
 * as a react-final-form component.
 *
 * <Field name="something"
 *        component={SelectFormAdapter}
 *        options={...} required={...} label={...} placeholder={...} />
 */

// eslint-disable-next-line
export const SelectFormAdapter = (props) => {
  const onChange = (val) => {
    // a callback set by final-form
    props.input.onChange(val);
    // a custom callback which might be passed by the component
    props.onChange?.(val);
  };

  return (
    <Select
      {...props}
      name={props.input.name}
      value={props.input.value}
      errorText={getError(props.meta)}
      onChange={onChange}
      onFocus={props?.input?.onFocus} // we have to pass callback from Form Field in order to
      onBlur={props?.input?.onBlur} // keep the field state consistent (pristine, touched, dirty...)
    />
  );
};
// TODO replace FormAdapter instances with SelectField

/**
 * react-select component calls onChange with the option object e.g.
 * { value, label } which, altho might be useful in some cases, differs from the default
 * select behavior. The expected parameter of the onChange callback is usually just
 * the `value` string. This discrepancy will force us to write unnecessary boilerplate code,
 * formatting the select values, for example in `submit` functions.
 * This component is final form wrapper to mitigate the non-standard behavior.
 **/
export const SelectField = ({
  disabled,
  label,
  labelProps,
  name,
  namespace,
  options,
  required,
  selectProps,
  hint,
  hintId,
  id,
  placeholder,
  ...props
}) => {
  return (
    <Field
      required={required}
      disabled={disabled}
      format={(id) => {
        const flatOptions = flattenOptions(options);
        return flatOptions.find(({ value }) => value === id);
      }}
      parse={(option) => option?.value || ''}
      name={name}
      {...props}
    >
      {({ input, meta }) => {
        return (
          <Select
            disabled={disabled}
            errorText={getError(meta)}
            hint={hint}
            hintId={hintId}
            labelProps={{ label, required, ...labelProps }}
            name={name}
            namespace={namespace}
            options={options}
            required={required}
            value={input.value}
            id={id}
            placeholder={placeholder}
            {...input}
            {...selectProps}
          />
        );
      }}
    </Field>
  );
};

SelectField.propTypes = {
  ...Field.propTypes,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  labelProps: Select.propTypes.labelProps,
  name: PropTypes.string.isRequired,
  namespace: PropTypes.string,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })
    ),
    PropTypes.array,
  ]).isRequired,
  required: PropTypes.bool,
  selectProps: PropTypes.shape(Select.propTypes),
};
