import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FormContext } from '../../../contexts';
import { randomUUID } from '../../../utils';

const RENDER_PROPS = ['component', 'render', 'children'];

// It receives react final form object as an argument, and uses form
// state to calculate whether the form is dirty (returns a boolean).
// We cannot simply use the 'dirty' value from the form state
// because we want to ignore cases when value is changed programmatically
const calculateDirty = (form) => {
  const { dirtyFields, modified, submitting } = form.getState();
  // check if there are dirty fields, and make sure that they are
  // edited by user, and not programmatically
  return Object.keys(dirtyFields).some(
    (field) => !submitting && dirtyFields[field] && modified[field]
  );
};

// a function that returns modified render prop for the Final Form component
// this way we are able to bake in "prevent leave page" functionality directly
// into the Form component. If the `forcePreventUnload` is false we decide onbeforeunload
// value based on the 'dirtyFields' value
const renderWrapper =
  (render, forcePreventUnload, formId, setDirty) => (props) => {
    const isDirty = forcePreventUnload ? true : calculateDirty(props.form);
    useEffect(() => {
      setDirty({ id: formId, isDirty: isDirty });
    }, [isDirty]);

    return render(props);
  };

// A wrapper function that return props, and modify some of them if necessary.
const handleProps = (
  props,
  preventUnload,
  forcePreventUnload,
  idRef,
  setDirty
) => {
  if (preventUnload || forcePreventUnload) {
    RENDER_PROPS.some((renderProp) => {
      if (props[renderProp]) {
        props[renderProp] = renderWrapper(
          props[renderProp],
          forcePreventUnload,
          idRef.current,
          setDirty
        );
        return true;
      }
    });
  }
  const { onSubmit } = props;

  props.onSubmit = async (values, form, callback) => {
    // here we make sure set form to not dirty before
    // submitting. This will allow us to switch page on
    // successfull submit, given that form in question is
    // the only form on page, or all the other forms are
    // not dirty as well
    await setDirty({ id: idRef.current, isDirty: false });
    const errors = await onSubmit(values, form, callback);

    // restore the value of onbeforeunload if there are errors,
    // since we've never left the page
    if (errors) {
      setDirty({ id: idRef.current, isDirty: calculateDirty(form) });
    }

    return errors;
  };
  return props;
};

/**
 * We created a wrapper around the react-final-form `Form` component because
 * we wanted to expand it with functionality of being able to prevent the
 * page unload (changing the page) if there are unsaved changes.
 * To be able to do that we expanded `Form` with a `preventUnload` property, which, if
 * set to `true` would check whether the form has `dirty` fields.
 * it would raise a modal if there are indeed such fields, asking the user to confirm its choice.
 * All other props (besides the `preventUnload`) will be passed to the react-final-form
 * component. Expected behavior is same as the one of the library component, with added
 * "ask user before changing the page if there are unsaved changes" functionality.
 * when `forcePreventUnload` is true, we will raise a dialog on any attempted page change,
 * regardless of the `dirty` value
 **/
export const Form = ({ preventUnload, forcePreventUnload, ...formProps }) => {
  const idRef = useRef(randomUUID());
  const setDirty = useContext(FormContext);

  useEffect(() => {
    return () => {
      setDirty(idRef.current, false);
    };
  });

  return (
    <FinalForm
      {...handleProps(
        formProps,
        preventUnload,
        forcePreventUnload,
        idRef,
        setDirty
      )}
    />
  );
};

Form.propTypes = {
  ...FinalForm.propTypes,
  preventUnload: PropTypes.bool,
  forcePreventUnload: PropTypes.bool,
};
