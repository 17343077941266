import {
  borderLightGray,
  lightBlue,
  lightGray,
  modalZIndex,
} from '../../styles/variables';
import styled from 'styled-components';

export const defaultBoxShadow = `inset 0 -1px 0 ${borderLightGray}`;
const customStyles = {
  control: (provided) => ({
    ...provided,
    background: 'transparent',
    borderTopStyle: 'none',
    borderLeftStyle: 'none',
    borderRightStyle: 'none',
    borderRadius: 'none',
    border: 'none',
    boxShadow: defaultBoxShadow,
  }),
  indicatorSeparator: () => ({}),
  valueContainer: (provided) => ({
    ...provided,
    paddingLeft: 0,
    '&>div': {
      color: lightGray,
    },
  }),
  // z-index higher than modla overlay
  menuPortal: (provided) => ({
    ...provided,
    zIndex: modalZIndex + 5,
    background: 'red',
  }),
  input: (provided) => ({
    ...provided,
    '&>input:focus, &>input': {
      boxShadow: 'none !important',
    },
  }),
  menuList: (base, state) => ({
    ...base,
    padding: state?.selectProps?.dropdownHint ? '4px 0 0 0' : '4px 0',
  }),
  option: (provided) => ({ ...provided, cursor: 'pointer' }),
};

const customStylesWithError = (hasError) => ({
  ...customStyles,
  control: (provided) => ({
    ...customStyles.control(provided),
    boxShadow: hasError ? 'inset 0 -2px 0 #e51c23' : defaultBoxShadow,
    '&:focus-within': {
      boxShadow: !hasError ? `inset 0 -2px 0 ${lightBlue}` : defaultBoxShadow,
    },
  }),
});

const scrollbarStyle = (showScrollbar) =>
  showScrollbar
    ? {
        menuList: (base) => ({
          ...base,
          height: '250px',

          '::-webkit-scrollbar': {
            width: '9px',
          },
          '::-webkit-scrollbar-track': {
            background: 'lightgray',
          },
          '::-webkit-scrollbar-thumb': {
            background: '#888',
          },
          '::-webkit-scrollbar-thumb:hover': {
            background: '#555',
          },
        }),
      }
    : {};

export const generateStyles = ({ showScrollbar, hasError }) => {
  return {
    ...customStylesWithError(hasError),
    ...scrollbarStyle(showScrollbar),
  };
};

export const DropdownHint = styled.small`
  position: sticky;
  display: flex;
  bottom: 0;
  padding: 0 8px;
  background: white;
  color: ${lightGray};
  box-shadow: 0 -5px 5px -5px ${borderLightGray};
`;
